import React, { useState } from 'react'
import { Button, Card, Col, Container, FormCheck, Row ,Overlay, Tooltip, OverlayTrigger} from 'react-bootstrap'
import * as Icon from "react-bootstrap-icons";
import CreateToDoListModal from './modal/CreateToDoListModal';
import DeleteToDOListModal from './modal/DeleteToDoListModal';
import EditToDOListModal from './modal/EditToDoListModal';
import CreateTaskModel from '../Model/CreateTaskModel';
import ViewToDOListModal from './modal/ViewToDoListModal';

const ToDoList = () => {

  // Define state variables
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [showtodolistmodal, setShowToDoListModal] = useState(false);
  const [showdeletemodal, setShowDeleteModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = useState(false);
  const[showcreatemodal,setShowCreateModal] = useState(false);

  // for button
  const [showToDoListCard, setShowToDoListCard] = useState(true);
  const [showCompletedCard, setShowCompletedCard] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);

  
  // for description
   const initialText = `Lorem Ipsum is simply dummy text of the printing and typesetting industry.
   Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
   when an unknown printer took a galley of type and scrambled it to make a type specimen book.`;

  // showing for current date
  const currentDate = new Date();

  // Extract the date components (day, month, and year)
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
  const year = currentDate.getFullYear();

  // function for create task model
  const handleCloseCreateModel = () => {setShowCreateModal(false) };

  // function for crearte todo list modal
  const handleCloseToDOListModel = () => { setShowToDoListModal(false);};

  // function for delete todo list modal
   const handleDeleteModalClose = () => {setShowDeleteModal(false);};
  
  // for edit open and close modal
  const handleShowEditModal = () => {setShowEdit(true);};
  const handlaEditModalClose = () => {setShowEdit(false);};

    
  // for View open and close modal
  const handlaViewModalClose = () => {setShowView(false);};

  // Format the date as a string (e.g., "MM/DD/YYYY")
  const formattedDate = `${day}/${month}/${year}`;

  // Function to add a new task
  const addTask = () => {
    if (newTask) {
      const task = {
        text: newTask,
        completed: false,
      };

      setTasks([...tasks, task]);
      setNewTask('');
    }
  };

   const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
       addTask();
    }
  };

 


  return (
    <div className="AssignTask outletPadding" data-aos="fade-up">
      <Container>
        {showcreatemodal && <CreateTaskModel show={showcreatemodal} close={handleCloseCreateModel} />}
        {showtodolistmodal && (<CreateToDoListModal show={showtodolistmodal} close={handleCloseToDOListModel}/>)}
        {showEdit && <EditToDOListModal show={showEdit} close={handlaEditModalClose} />}
        {showView && <ViewToDOListModal show={showView} close={handlaViewModalClose} />}
        {showdeletemodal &&  <DeleteToDOListModal show={showdeletemodal} close={handleDeleteModalClose}/>}
        {}
         
         
        {/* <Row className='d-flex justify-content-between '>
          <Col md={6} className="d-flex justify-content-start align-items-center">
            <h6>Today<span className='ms-1'>{formattedDate}</span></h6>
          </Col>
        </Row> */}

         <Row className='d-flex justify-content-between align-items-center'>
          <Col md={6} className="d-flex justify-content-start align-items-center">
            <Button className="primaryBtn me-3" onClick={() => {setShowToDoListCard(true);setShowCompletedCard(false);}}>
              To Do List
            </Button>
            <Button className="primaryBtn me-3" onClick={() => {setShowToDoListCard(false); setShowCompletedCard(true);}}>
              Completed
            </Button>
          </Col>

          <Col sm={12} md={6} className='d-flex justify-content-end align-items-center'>
             <Button className="activeBtn me-3" onClick={() => { setShowToDoListModal(true) }}>
              <Icon.PersonAdd className="me-2" size={16}/>
              Create List
            </Button>
          </Col>
        </Row>
        <Row>

          {/* for to do list */}
          {showToDoListCard && (
            <>
            {/* for hover card */}
             {/* <Col xs={12} sm={6} md={4} lg={3}>
              <Card
                className={`my-3 ${isHovered1 ? 'card-hovered' : ''}`}
                onMouseEnter={() => setIsHovered1(true)}
                onMouseLeave={() => setIsHovered1(false)}
              >
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6 className="m-0">Pmc Care</h6>
                    </div>
                    <div className="d-flex">
                      <Icon.Pencil className="icon me-3" size={18} color="#47AB64" data-toggle="tooltip" title="Edit"onClick={() => {handleShowEditModal();}}/>
                      <Icon.Trash className="icon" size={18} color="#FF0808" data-toggle="tooltip" title="Delete" onClick = {() => setShowDeleteModal(true)}/>
                    </div>
                  </div>
                  
                   <div className={`description ${isHovered1 ? 'expanded' : 'collapsed'}`}style={{ fontSize: '14px', color: '#333', marginTop: '10px' }} >
                      {isHovered1 ? initialText : initialText.length > 200 ? initialText.substring(0, 100) + "..." : initialText}
                   </div>

                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="date mt-3" style={{ fontSize: '12px', color: '#777' }}>{formattedDate}</div>
                    <Button className="mt-3 primaryBtn" onClick={() => { setShowCreateModal(true) }}>
                      Assign
                    </Button>
                  </div>
                </Card.Body>
              </Card>
             </Col>

             <Col xs={12} sm={6} md={4} lg={3}>
              <Card
                className={`my-3 ${isHovered2 ? 'card-hovered' : ''}`}
                onMouseEnter={() => setIsHovered2(true)}
                onMouseLeave={() => setIsHovered2(false)}
              >
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6 className="m-0">Pmc Care</h6>
                    </div>
                    <div className="d-flex">
                      <Icon.Pencil className="icon me-3" size={18} color="#47AB64" data-toggle="tooltip" title="Edit"onClick={() => {handleShowEditModal();}}/>
                      <Icon.Trash className="icon" size={18} color="#FF0808" data-toggle="tooltip" title="Delete" onClick = {() => setShowDeleteModal(true)}/>
                    </div>
                  </div>
                  
                   <div className={`description ${isHovered2 ? 'expanded' : 'collapsed'}`}style={{ fontSize: '14px', color: '#333', marginTop: '10px' }} >
                      {isHovered2 ? initialText : initialText.length > 200 ? initialText.substring(0, 100) + "..." : initialText}
                   </div>

                   <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="date mt-3" style={{ fontSize: '12px', color: '#777' }}>{formattedDate}</div>
                    <Button className="mt-3 primaryBtn" onClick={() => { setShowCreateModal(true) }}>
                      Assign
                    </Button>
                  </div>
                </Card.Body>
              </Card>
             </Col>

             <Col xs={12} sm={6} md={4} lg={3}>
              <Card
                className={`my-3 ${isHovered3 ? 'card-hovered' : ''}`}
                onMouseEnter={() => setIsHovered3(true)}
                onMouseLeave={() => setIsHovered3(false)}
              >
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6 className="m-0">Pmc Care</h6>
                    </div>
                    <div className="d-flex">
                      <Icon.Pencil className="icon me-3" size={18} color="#47AB64" data-toggle="tooltip" title="Edit"onClick={() => {handleShowEditModal();}}/>
                      <Icon.Trash className="icon" size={18} color="#FF0808" data-toggle="tooltip" title="Delete" onClick = {() => setShowDeleteModal(true)}/>
                    </div>
                  </div>
                  
                   <div className={`description ${isHovered3 ? 'expanded' : 'collapsed'}`}style={{ fontSize: '14px', color: '#333', marginTop: '10px' }} >
                      {isHovered3 ? initialText : initialText.length > 200 ? initialText.substring(0, 100) + "..." : initialText}
                   </div>

                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="date mt-3" style={{ fontSize: '12px', color: '#777' }}>{formattedDate}</div>
                    <Button className="mt-3 primaryBtn" onClick={() => { setShowCreateModal(true) }}>
                      Assign
                    </Button>
                  </div>
                </Card.Body>
              </Card>
             </Col>

             <Col xs={12} sm={6} md={4} lg={3}>
              <Card
                className={`my-3 ${isHovered4 ? 'card-hovered' : ''}`}
                onMouseEnter={() => setIsHovered4(true)}
                onMouseLeave={() => setIsHovered4(false)}
              >
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6 className="m-0">Pmc Care</h6>
                    </div>
                    <div className="d-flex">
                      <Icon.Pencil className="icon me-3" size={18} color="#47AB64" data-toggle="tooltip" title="Edit"onClick={() => {handleShowEditModal();}}/>
                      <Icon.Trash className="icon" size={18} color="#FF0808" data-toggle="tooltip" title="Delete" onClick = {() => setShowDeleteModal(true)}/>
                    </div>
                  </div>
                  
                   <div className={`description ${isHovered4 ? 'expanded' : 'collapsed'}`}style={{ fontSize: '14px', color: '#333', marginTop: '10px' }} >
                      {isHovered4 ? initialText : initialText.length > 200 ? initialText.substring(0, 100) + "..." : initialText}
                   </div>

                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="date mt-3" style={{ fontSize: '12px', color: '#777' }}>{formattedDate}</div>
                    <Button className="mt-3 primaryBtn" onClick={() => { setShowCreateModal(true) }}>
                      Assign
                    </Button>
                  </div>
                </Card.Body>
              </Card>
             </Col> */}

             {/* for simple card */}

             <Col xs={12} sm={6} md={4} lg={3}>
                <Card  className={`my-3 ${showToDoListCard ? 'card-animation' : 'card-hidden'} card-animated`} style={{ borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h6 className="m-0">Pmc Care</h6>
                      </div>
                      <div className="d-flex">
                          <Icon.Eye className="icon me-3" size={18} color = '#203759' data-toggle="tooltip" title="View" onClick={() => {setShowView(true);}} />
                          <Icon.Pencil className="icon me-3" size={18} color = "#47AB64" data-toggle="tooltip" title="Edit"  onClick={() => {handleShowEditModal();}}/>
                          <Icon.Trash className="icon" size={18} color = "#FF0808" data-toggle="tooltip" title="Delete" onClick = {() => setShowDeleteModal(true)}/>
                      </div>
                    </div>

                    <div style={{ fontSize: '14px', color: '#333', marginTop: '15px' }}>
                      <p>
                        {initialText.length > 200 ? initialText.substring(0, 100) + "..." : initialText}
                      </p>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="date mt-3" style={{ fontSize: '12px', color: '#777' }}>{formattedDate}</div>
                    <Button className="mt-3 primaryBtn" onClick={() => { setShowCreateModal(true) }}>
                      Assign
                    </Button>
                  </div>
                  </Card.Body>
                </Card>
             </Col>

             <Col xs={12} sm={6} md={4} lg={3}>
              <Card  className={`my-3 ${showToDoListCard ? 'card-animation' : 'card-hidden'} card-animated`} style={{ borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6 className="m-0">Pmc Care</h6>
                    </div>
                    <div className="d-flex">
                        <Icon.Eye className="icon me-3" size={18} color = '#203759' data-toggle="tooltip" title="View" onClick={() => {handleShowEditModal();}} />
                        <Icon.Pencil className="icon me-3" size={18} color = "#47AB64" data-toggle="tooltip" title="Edit"  onClick={() => {handleShowEditModal();}}/>
                        <Icon.Trash className="icon" size={18} color = "#FF0808" data-toggle="tooltip" title="Delete" onClick = {() => setShowDeleteModal(true)}/>
                    </div>
                  </div>

                  <div style={{ fontSize: '14px', color: '#333', marginTop: '15px' }}>
                     <p>
                      {initialText.length > 200 ? initialText.substring(0, 100) + "..." : initialText}
                     </p>
                  </div>

                   <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="date mt-3" style={{ fontSize: '12px', color: '#777' }}>{formattedDate}</div>
                    <Button className="mt-3 primaryBtn" onClick={() => { setShowCreateModal(true) }}>
                      Assign
                    </Button>
                  </div>
                </Card.Body>
              </Card>
             </Col>

             <Col xs={12} sm={6} md={4} lg={3}>
              <Card  className={`my-3 ${showToDoListCard ? 'card-animation' : 'card-hidden'} card-animated`} style={{ borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6 className="m-0">Pmc Care</h6>
                    </div>
                    <div className="d-flex">
                        <Icon.Eye className="icon me-3" size={18} color = '#203759' data-toggle="tooltip" title="View" onClick={() => {handleShowEditModal();}} />
                        <Icon.Pencil className="icon me-3" size={18} color = "#47AB64" data-toggle="tooltip" title="Edit"  onClick={() => {handleShowEditModal();}}/>
                        <Icon.Trash className="icon" size={18} color = "#FF0808" data-toggle="tooltip" title="Delete" onClick = {() => setShowDeleteModal(true)}/>
                    </div>
                  </div>

                  <div style={{ fontSize: '14px', color: '#333', marginTop: '15px' }}>
                     <p>
                      {initialText.length > 200 ? initialText.substring(0, 100) + "..." : initialText}
                     </p>
                  </div>

                   <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="date mt-3" style={{ fontSize: '12px', color: '#777' }}>{formattedDate}</div>
                    <Button className="mt-3 primaryBtn" onClick={() => { setShowCreateModal(true) }}>
                      Assign
                    </Button>
                  </div>
                </Card.Body>
              </Card>
             </Col>

             <Col xs={12} sm={6} md={4} lg={3}>
              <Card  className={`my-3 ${showToDoListCard ? 'card-animation' : 'card-hidden'} card-animated`} style={{ borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6 className="m-0">Pmc Care</h6>
                    </div>
                    <div className="d-flex">
                        <Icon.Eye className="icon me-3" size={18} color = '#203759' data-toggle="tooltip" title="View" onClick={() => {handleShowEditModal();}} />
                        <Icon.Pencil className="icon me-3" size={18} color = "#47AB64" data-toggle="tooltip" title="Edit"  onClick={() => {handleShowEditModal();}}/>
                        <Icon.Trash className="icon" size={18} color = "#FF0808" data-toggle="tooltip" title="Delete" onClick = {() => setShowDeleteModal(true)}/>
                    </div>
                  </div>

                  <div style={{ fontSize: '14px', color: '#333', marginTop: '15px' }}>
                     <p>
                      {initialText.length > 200 ? initialText.substring(0, 100) + "..." : initialText}
                     </p>
                  </div>

                   <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="date mt-3" style={{ fontSize: '12px', color: '#777' }}>{formattedDate}</div>
                    <Button className="mt-3 primaryBtn" onClick={() => { setShowCreateModal(true) }}>
                      Assign
                    </Button>
                  </div>
                </Card.Body>
              </Card>
             </Col>

            </>
            
          )}

          {/* for completed list */}
          {showCompletedCard && (
            <>
               <Col xs={12} sm={6} md={4} lg={3}>
              <Card
                className={`my-3 ${isHovered1 ? 'card-hovered' : ''}`}
                onMouseEnter={() => setIsHovered1(true)}
                onMouseLeave={() => setIsHovered1(false)}
              >
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6 className="m-0">Pmc Care</h6>
                    </div>
                    <div className="d-flex">
                      <Icon.Trash className="icon" size={18} color="#FF0808" data-toggle="tooltip" title="Delete" onClick = {() => setShowDeleteModal(true)}/>
                    </div>
                  </div>
                  
                   <div className={`description ${isHovered1 ? 'expanded' : 'collapsed'}`}style={{ fontSize: '14px', color: '#333', marginTop: '10px' }} >
                      {isHovered1 ? initialText : initialText.length > 200 ? initialText.substring(0, 100) + "..." : initialText}
                   </div>

                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="date mt-3" style={{ fontSize: '12px', color: '#777' }}>{formattedDate}</div>
                   </div>
                </Card.Body>
              </Card>
             </Col>

             <Col xs={12} sm={6} md={4} lg={3}>
              <Card
                className={`my-3 ${isHovered2 ? 'card-hovered' : ''}`}
                onMouseEnter={() => setIsHovered2(true)}
                onMouseLeave={() => setIsHovered2(false)}
              >
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6 className="m-0">Pmc Care</h6>
                    </div>
                    <div className="d-flex">
                      <Icon.Trash className="icon" size={18} color="#FF0808" data-toggle="tooltip" title="Delete" onClick = {() => setShowDeleteModal(true)}/>
                    </div>
                  </div>
                  
                   <div className={`description ${isHovered2 ? 'expanded' : 'collapsed'}`}style={{ fontSize: '14px', color: '#333', marginTop: '10px' }} >
                      {isHovered2 ? initialText : initialText.length > 200 ? initialText.substring(0, 100) + "..." : initialText}
                   </div>

                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="date mt-3" style={{ fontSize: '12px', color: '#777' }}>{formattedDate}</div>
                   </div>
                </Card.Body>
              </Card>
             </Col>

             <Col xs={12} sm={6} md={4} lg={3}>
              <Card
                className={`my-3 ${isHovered3 ? 'card-hovered' : ''}`}
                onMouseEnter={() => setIsHovered3(true)}
                onMouseLeave={() => setIsHovered3(false)}
              >
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6 className="m-0">Pmc Care</h6>
                    </div>
                    <div className="d-flex">
                      <Icon.Trash className="icon" size={18} color="#FF0808" data-toggle="tooltip" title="Delete" onClick = {() => setShowDeleteModal(true)}/>
                    </div>
                  </div>
                  
                   <div className={`description ${isHovered3 ? 'expanded' : 'collapsed'}`}style={{ fontSize: '14px', color: '#333', marginTop: '10px' }} >
                      {isHovered3 ? initialText : initialText.length > 200 ? initialText.substring(0, 100) + "..." : initialText}
                   </div>

                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="date mt-3" style={{ fontSize: '12px', color: '#777' }}>{formattedDate}</div>
                   </div>
                </Card.Body>
              </Card>
             </Col>

             <Col xs={12} sm={6} md={4} lg={3}>
              <Card
                className={`my-3 ${isHovered4 ? 'card-hovered' : ''}`}
                onMouseEnter={() => setIsHovered4(true)}
                onMouseLeave={() => setIsHovered4(false)}
              >
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6 className="m-0">Pmc Care</h6>
                    </div>
                    <div className="d-flex">
                      <Icon.Trash className="icon" size={18} color="#FF0808" data-toggle="tooltip" title="Delete" onClick = {() => setShowDeleteModal(true)}/>
                    </div>
                  </div>
                  
                   <div className={`description ${isHovered4 ? 'expanded' : 'collapsed'}`}style={{ fontSize: '14px', color: '#333', marginTop: '10px' }} >
                      {isHovered4 ? initialText : initialText.length > 200 ? initialText.substring(0, 100) + "..." : initialText}
                   </div>

                   <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="date mt-3" style={{ fontSize: '12px', color: '#777' }}>{formattedDate}</div>
                   </div>
                   
                </Card.Body>
              </Card>
             </Col>
            </>
          )}
        </Row>
      </Container>
    </div>
  )
}

export default ToDoList
