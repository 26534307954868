import React, { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import { InputGroup, Modal, Button, Form, Row, Col } from "react-bootstrap";

const EditToDOListModal = ({ show, close}) => {

  return (
    <>
      <ToastContainer autoClose = {5000}/>
        <Modal show={show} onHide={close} size="md" centered backdrop='static' keyboard={false}>
        <Modal.Header closeButton className="mngmodelheader">
            <Modal.Title><h5>Edit List</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body size="lg">
            <Row className='mb-3'>
                <Col sm={12} md={12}>
                    <InputGroup>
                        <Form.Control
                          value = {"PMC Care"}
                          className="input-field project-inputgroup"
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={12}>
                    <InputGroup>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          value={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."}
                          className="input-field project-inputgroup"
                        />
                    </InputGroup>
                </Col>
            </Row>
        </Modal.Body>

        <Modal.Footer className="text-center mt-4">
          <Button className="primaryBtn">Update</Button>
        </Modal.Footer>
        </Modal>
    </>
  );
};

export default EditToDOListModal;
