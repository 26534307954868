import React, {useState } from 'react'
import { InputGroup, Modal, Button, Form, Col, Row} from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateToDoListModal = ({ show, close }) => {
  // usestate
  const [tasktitle, setTaskTitle] = useState("")
  const [taskdesc, setTaskDesc] = useState("")


    // Handle the modal opening animation
  const modalClass = show ? "slide-in-from-top" : "";

  return (
    <>
     <ToastContainer autoClose = {5000}/>
        <Modal show={show} onHide={close} size="md" centered backdrop='static' keyboard={false}>
        <Modal.Header closeButton className="mngmodelheader">
            <Modal.Title><h5>Create List</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body size="lg">
            <Row className='mb-3'>
                <Col sm={12} md={12}>
                    <InputGroup>
                        <Form.Control
                          placeholder="Title"
                          className="input-field project-inputgroup"
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={12}>
                    <InputGroup>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          placeholder="Write the description of task..."
                          className="input-field project-inputgroup"
                        />
                    </InputGroup>
                </Col>
            </Row>
        </Modal.Body>

        <Modal.Footer className="text-center mt-4">
            <Button className="primaryBtn ">Assign</Button>
            <Button className="activeBtn">Save</Button>
        </Modal.Footer>
        </Modal>
    </>
    
  )
}

export default CreateToDoListModal