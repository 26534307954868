import axios from 'axios';
import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeleteToDoListModal = ({ show, close, taskId }) => {

  
  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={close} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton style={{ color: "black" }}>
          <Modal.Title><h5>Delete List</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body className='py-4'>
          <p style={{ color: "black", fontSize: "15px", margin: '0px' }}>
            Are you sure you want to delete this Records?👎
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='secondaryBtn' onClick={close}>Cancel</Button>
          <Button variant="danger">Delete</Button>
        </Modal.Footer>
      </Modal >
    </>

  )
}

export default DeleteToDoListModal