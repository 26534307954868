import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Badge, Modal } from 'react-bootstrap'
import DisplayHoursAndMinutes from '../Convertor/DisplayHoursAndMinutes'

const SubTaskModel = ({ show, close, taskId }) => {
    const payload = {
        headTaskId: taskId
    }

    const [subTaskData, setSubTaskData] = useState([])
    useEffect(() => {
        if (taskId) {
            axios.post(process.env.REACT_APP_BASE_URL + '/Task/perticularTaskSubTask', payload)
                .then((res) => {
                    setSubTaskData(res.data.data)
                })
                .catch((err) => {
                    console.log("Error", err)
                })
        }
    }, [taskId])

    const handleCloseModal = () => {
        setSubTaskData([])
        close()
    }

    return (
        <Modal show={show} onHide={handleCloseModal} size='lg' dialogClassName="custom-modal" centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <h5>SubTask</h5>
            </Modal.Header >
            <Modal.Body >
                 <div className='outer-wrapper'>
                    <div className='table-wrapper'>
                        <table>
                            <thead>
                                <tr className='tbl-header'>
                                    <th style={{ minWidth: "100px" }}>Sr No</th>
                            <th>Sub Task</th>
                            <th>Actual Start Date</th>
                            <th>Actual End Date</th>
                            <th>Actual Hours</th>
                            <th>Task Completion</th>
                            <th colSpan={2}>Remarks</th>
                            <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                        {subTaskData !== null &&
                            subTaskData?.length > 0 &&
                            subTaskData !== undefined
                            ? subTaskData
                                .map((values, index) => {
                                    return (
                                        <>
                                            <tr
                                                style={{
                                                    textAlign: "center",
                                                    minWidth: "100px",
                                                }}
                                            >
                                                <td>{index + 1}</td>
                                                <td>{values?.taskName}</td>

                                                <td>
                                                    {new Date(values?.actualStartDate).toLocaleDateString('en-GB')}
                                                </td>

                                                <td>
                                                    {new Date(values?.actualEndtDate).toLocaleDateString('en-GB')}
                                                </td>
                                                <td><DisplayHoursAndMinutes value={values?.actualHours}/></td>
                                                <td>
                                                    {values?.complitionByResource}
                                                    {"%"}
                                                </td>
                                                <td colSpan={2}>{values?.remark}</td>
                                                <td>
                                                    <Badge bg={values?.status === "pending" ? "warning p-1" : "success p-1 text-white"} text="dark">{values?.status}</Badge>
                                                   
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })
                            : <tr><td colSpan={14} className='text-center'>No task available</td></tr>}
                    </tbody>
                        </table>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SubTaskModel